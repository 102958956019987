.clickArea {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9;
}

.loginCard {
  width: 20%;
  height: auto;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-image: url("../img/authbg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0 0 5px 0 gray;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 99;
}

.loginCard > h1 {
  margin: 1rem 0 2rem 0;
  font-family: product;
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
}

.loginCard > form {
  display: flex;
  flex-direction: column;
}

.loginCard > form > label {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.loginCard > form > input {
  height: 3rem;
  margin-bottom: 1.15rem;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 8px;
  border: #7e7e7e solid 1px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #dfdfdf;
}

form > button {
  color: white;
  background: #2e86de;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.loginCard > form > button:hover {
  background: #1059a1;
}

.loginCard > p {
  text-align: center;
  cursor: pointer;
  font-family: avenirs;
  font-size: 1rem;
  margin: 20px 0;
}

.loginCard > p:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1440px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 25%;
    height: auto;
    padding: 1rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 2rem 0;
    font-family: product;
    font-size: 2.75rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }

  .loginCard > form > input {
    height: 3rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 1rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1366px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 25%;
    height: auto;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.75rem 0;
    font-family: product;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.35rem;
    font-size: 1.5rem;
  }

  .loginCard > form > input {
    height: 2.75rem;
    margin-bottom: 0.85rem;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.75rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.8rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1024px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 30%;
    height: auto;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }

  .loginCard > form > input {
    height: 2.5rem;
    margin-bottom: 0.85rem;
    font-size: 0.85rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.75rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 900px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 35%;
    height: auto;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }

  .loginCard > form > input {
    height: 2.5rem;
    margin-bottom: 0.85rem;
    font-size: 0.85rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.75rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 42.5%;
    height: auto;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 2.25rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }

  .loginCard > form > input {
    height: 2.5rem;
    margin-bottom: 0.85rem;
    font-size: 0.85rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.75rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 52.5%;
    height: auto;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 2.35rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }

  .loginCard > form > input {
    height: 2.5rem;
    margin-bottom: 0.65rem;
    font-size: 0.85rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.75rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 480px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 80%;
    height: auto;
    padding: 0.5rem 2.25rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.75rem;
  }

  .loginCard > form > input {
    height: 3rem;
    margin-bottom: 0.65rem;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 1rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 360px) {
  .clickArea {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
  }

  .loginCard {
    width: 90%;
    height: auto;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    background-image: url("../img/authbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 0 gray;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 99;
  }

  .loginCard > h1 {
    margin: 1rem 0 1.25rem 0;
    font-family: product;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }

  .loginCard > form {
    display: flex;
    flex-direction: column;
  }

  .loginCard > form > label {
    margin-bottom: 0.25rem;
    font-size: 1.5rem;
  }

  .loginCard > form > input {
    height: 2.5rem;
    margin-bottom: 0.65rem;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 8px;
    border: #7e7e7e solid 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #dfdfdf;
  }

  .loginCard > form > button {
    color: white;
    background: #2e86de;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .loginCard > form > button:hover {
    background: #1059a1;
  }

  .loginCard > p {
    text-align: center;
    cursor: pointer;
    font-family: avenirs;
    font-size: 0.85rem;
    margin: 20px 0;
  }

  .loginCard > p:hover {
    text-decoration: underline;
  }
}
