.navContainer {
  height: 600px;
  background-color: #00000070;
  background-size: cover;
  background-repeat: no-repeat;
}

.navWrapper {
  padding: 30px 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bgVid {
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
}

.navWrapper > img {
  height: 75px;
  cursor: pointer;
}

.btnGroup {
  display: flex;
  gap: 1rem;
}

.btnGroup > button {
  width: 125px;
  padding: 0.5rem 0;
  font-family: product;
  font-weight: 900;
  font-size: 1rem;
  color: white;
  border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
  cursor: pointer;
}

.btnLog {
  background: none;
  border: 2px solid white;
}

.btnReg {
  border: none;
  background: #2e86de;
}

.navBanner {
  width: 100%;
  box-sizing: border-box;
  padding: 50px 125px;
}

.bannerTitle {
  margin: 0;
  color: white;
  font-size: 4rem;
  font-family: avenirs;
}

.bannerDesc {
  color: white;
  font-size: 1.5rem;
  font-family: avenirs;
}

@media only screen and (max-width: 1440px) {
  .navContainer {
    height: 550px;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 550px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 65px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 1rem;
  }

  .btnGroup > button {
    width: 115px;
    padding: 0.5rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.9rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 45px 100px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 3.5rem;
    font-family: avenirs;
  }

  .bannerDesc {
    color: white;
    font-size: 1.35rem;
    font-family: avenirs;
  }
}

@media only screen and (max-width: 1366px) {
  .navContainer {
    height: 500px;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 25px 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 500px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 60px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 1rem;
  }

  .btnGroup > button {
    width: 105px;
    padding: 0.4rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.9rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 75px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 3.25rem;
    font-family: avenirs;
  }

  .bannerDesc {
    color: white;
    font-size: 1.35rem;
    font-family: avenirs;
  }
}

@media only screen and (max-width: 1024px) {
  .navContainer {
    height: 100vh;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 25px 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 60px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 1rem;
  }

  .btnGroup > button {
    width: 105px;
    padding: 0.4rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.9rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 75px 75px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 3.25rem;
    font-family: avenirs;
  }

  .bannerDesc {
    color: white;
    font-size: 1.35rem;
    font-family: avenirs;
  }
}

@media only screen and (max-width: 900px) {
  .navContainer {
    height: 100vh;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 25px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 55px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 0.75rem;
  }

  .btnGroup > button {
    width: 105px;
    padding: 0.3rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.75rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 85px 75px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 3rem;
    font-family: avenirs;
  }

  .bannerDesc {
    color: white;
    font-size: 1.25rem;
    font-family: avenirs;
  }
}

@media only screen and (max-width: 768px) {
  .navContainer {
    height: 450px;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 25px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 450px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 50px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 0.5rem;
  }

  .btnGroup > button {
    width: 95px;
    padding: 0.3rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.65rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 75px 35px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 2.5rem;
    font-family: avenirs;
  }

  .bannerDesc {
    color: white;
    font-size: 1rem;
    font-family: avenirs;
  }
}

@media only screen and (max-width: 600px) {
  .navContainer {
    height: 400px;
    background-color: #00000070;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 400px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 40px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 0.5rem;
  }

  .btnGroup > button {
    width: 85px;
    padding: 0.3rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.6rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 35px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 2rem;
    font-family: avenirs;
    text-align: center;
  }

  .bannerDesc {
    color: white;
    font-size: 0.9rem;
    font-family: avenirs;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .navContainer {
    height: 350px;
    background-color: #00000090;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 350px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 35px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 0.25rem;
  }

  .btnGroup > button {
    width: 75px;
    padding: 0.3rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.55rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 2px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 35px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 1.75rem;
    font-family: avenirs;
    text-align: center;
  }

  .bannerDesc {
    color: white;
    font-size: 0.8rem;
    font-family: avenirs;
    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  .navContainer {
    height: 350px;
    background-color: #00000090;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navWrapper {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bgVid {
    width: 100%;
    height: 350px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  .navWrapper > img {
    height: 32.5px;
    cursor: pointer;
  }

  .btnGroup {
    display: flex;
    gap: 0.25rem;
  }

  .btnGroup > button {
    width: 70px;
    padding: 0.3rem 0;
    font-family: product;
    font-weight: 900;
    font-size: 0.5rem;
    color: white;
    border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    -moz-border-radius: 7.5px;
    -ms-border-radius: 7.5px;
    -o-border-radius: 7.5px;
    cursor: pointer;
  }

  .btnLog {
    background: none;
    border: 1.5px solid white;
  }

  .btnReg {
    border: none;
    background: #2e86de;
  }

  .navBanner {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 35px;
  }

  .bannerTitle {
    margin: 0;
    color: white;
    font-size: 1.5rem;
    font-family: avenirs;
    text-align: center;
  }

  .bannerDesc {
    color: white;
    font-size: 0.9rem;
    font-family: avenirs;
    text-align: center;
  }
}
