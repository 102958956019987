.writeContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 50px 75px;
}

.imgContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.imgWrapper {
  display: inline-block;
  position: relative;
  background: rgb(153, 153, 153);
  overflow: hidden;
  width: 40%;
  height: 400px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

.imgWrapper:hover {
  background-color: rgb(85, 85, 85);
}

.imgWrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.formContent {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.btnWrapper > button {
  width: 50%;
  border: none;
  height: 3rem;
  color: white;
  font-size: 1rem;
  font-family: avenirs;
  font-weight: 700;
  cursor: pointer;
}

.backBtn {
  background-color: #ff1d1d;
  border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
}

.backBtn:hover {
  background-color: #5f0707;
}

.saveBtn {
  background-color: #2e86de;
  color: white;
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
}

.saveBtn:hover {
  background-color: #1e5fa0;
}

.addText {
  width: 90%;
  height: 350px;
  color: #2e86de00;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  z-index: 9;
}

.addText:hover {
  color: white;
  outline: 4px dashed #2e86de;
  background-color: gray;
}

@media only screen and (max-width: 1440px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 75px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 2rem;
    text-align: left;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 50%;
    height: 400px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 3rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 90%;
    height: 350px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 1366px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 75px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 2rem;
    text-align: left;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 50%;
    height: 375px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 3rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 350px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 1024px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 75px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.75rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 70%;
    height: 375px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.5rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 350px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 900px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 75px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.75rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 80%;
    height: 350px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.25rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 325px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 768px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 50px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 90%;
    height: 350px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.25rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 325px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 600px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 25px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.25rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 325px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 480px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 15px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.25rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 275px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 4px dashed #2e86de;
    background-color: gray;
  }
}

@media only screen and (max-width: 360px) {
  .writeContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 15px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .imgContainer > h1 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    text-align: center;
  }

  .imgWrapper {
    display: inline-block;
    position: relative;
    background: rgb(153, 153, 153);
    overflow: hidden;
    width: 100%;
    height: 225px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }

  .imgWrapper:hover {
    background-color: rgb(85, 85, 85);
  }

  .imgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .formContent {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper > button {
    width: 50%;
    border: none;
    height: 2.25rem;
    color: white;
    font-size: 1rem;
    font-family: avenirs;
    font-weight: 700;
    cursor: pointer;
  }

  .backBtn {
    background-color: #ff1d1d;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
  }

  .backBtn:hover {
    background-color: #5f0707;
  }

  .saveBtn {
    background-color: #2e86de;
    color: white;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }

  .saveBtn:hover {
    background-color: #1e5fa0;
  }

  .addText {
    width: 95%;
    height: 200px;
    color: #2e86de00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    z-index: 9;
  }

  .addText:hover {
    color: white;
    outline: 3px dashed #2e86de;
    background-color: gray;
  }
}
