* {
  scrollbar-width: thin;
  scrollbar-color: #2e86de rgb(172, 172, 172);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(172, 172, 172);
}

*::-webkit-scrollbar-thumb {
  background-color: #2e86de;
  border-radius: 20px;
  border: 3px solid rgb(172, 172, 172);
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #e5e5e5;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
