.dropWrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9;
}

.dropdown {
  width: 12.5%;
  background: white;
  position: absolute;
  top: 142.5px;
  right: 125px;
  z-index: 99;
  padding: 0.5rem 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0 0 5px 1px gray;
}

.triangle {
  position: absolute;
  top: -35px;
  right: 15px;
  height: 45px;
  width: 45px;
}

hr {
  border: solid 1px #a8a8a8;
}

.menuOption {
  display: flex;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.menuOption:hover {
  background: rgb(211, 211, 211);
}

.menuOption > p {
  font-size: 1.25rem;
  font-weight: 900;
}

.imgWrapper {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgWrapper > img {
  height: 2.5rem;
}

@media only screen and (max-width: 1440px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 15%;
    background: white;
    position: absolute;
    top: 135px;
    right: 97.5px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -35px;
    right: 15px;
    height: 45px;
    width: 45px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    font-size: 1rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 2rem;
  }
}

@media only screen and (max-width: 1366px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 15%;
    background: white;
    position: absolute;
    top: 122.5px;
    right: 69px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -35px;
    right: 15px;
    height: 45px;
    width: 45px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    font-size: 1rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 17.5%;
    background: white;
    position: absolute;
    top: 122.5px;
    right: 69px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -35px;
    right: 15px;
    height: 45px;
    width: 45px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 20%;
    background: white;
    position: absolute;
    top: 115px;
    right: 42.5px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -30px;
    right: 10px;
    height: 35px;
    width: 35px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.75rem;
  }
}

@media only screen and (max-width: 768px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 20%;
    background: white;
    position: absolute;
    top: 105px;
    right: 27.5px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -25px;
    right: 10px;
    height: 30px;
    width: 30px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 25%;
    background: white;
    position: absolute;
    top: 87.5px;
    right: 22.5px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -25px;
    right: 10px;
    height: 30px;
    width: 30px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.7rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.25rem;
  }
}

@media only screen and (max-width: 480px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 35%;
    background: white;
    position: absolute;
    top: 77.5px;
    right: 20px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -20px;
    right: 10px;
    height: 25px;
    width: 25px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.5rem;
  }
}

@media only screen and (max-width: 360px) {
  .dropWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
  }

  .dropdown {
    width: 45%;
    background: white;
    position: absolute;
    top: 74px;
    right: 12.5px;
    z-index: 99;
    padding: 0.5rem 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 5px 1px gray;
  }

  .triangle {
    position: absolute;
    top: -20px;
    right: 10px;
    height: 25px;
    width: 25px;
  }

  hr {
    border: solid 1px #a8a8a8;
  }

  .menuOption {
    display: flex;
    align-items: center;
    gap: 12.5px;
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .menuOption:hover {
    background: rgb(211, 211, 211);
  }

  .menuOption > p {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 900;
  }

  .imgWrapper {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgWrapper > img {
    height: 1.5rem;
  }
}
